import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EngineService } from './services/service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = "Elton's Website";

  constructor(
    private router: Router,
    private engine: EngineService
  ) {}

  public ngOnInit() : void {
    console.log("welcome to elton's website! catch a fade.")
    this.router.events
    .pipe(
      filter((evt) => evt instanceof NavigationStart),
    )
    .subscribe((evt: NavigationStart) => {

      // this.engine.transitioning$.next({
      //   transitioning: true,
      //   url: evt.url,
      //   popstate: true
      // });
      if(evt.navigationTrigger == 'popstate') {
        this.engine.transitioning$.next({
          transitioning: true,
          url: evt.url,
          popstate: true
        });
      }
    })

  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TransitionData, ScrollEvent } from '../interfaces/global.interface';
//import { ParallaxService } from './parallax.service';

@Injectable({
  providedIn: 'root'
})
export class EngineService {
  public wh: number = 0;
  public ww: number = 0;
  public sww: number = 0;
  public pageY: number = 0;
  public previousY: number = 0;

  public resize$ = new Subject<void>();
  public render$ = new Subject<number>();
  public scroll$ = new Subject<ScrollEvent>();
  public navColor$ = new BehaviorSubject<string>(null);
  public navOff$ = new Subject<boolean>();
  //public three: ThreeRenderer;
  public transitioning: boolean = false;
  public viewEl: HTMLElement;
  public appCanvas: HTMLCanvasElement;
  private raf: number;
  public textCTX: CanvasRenderingContext2D;
  public isTouchScreen: boolean;

  private fThen: number = 0;
  private fElapsed: number = 0;
  private fpsInterval: number = 1000 / 60;
  // Subjects for Transition
  public transitioning$ = new Subject<TransitionData>();

  constructor(
    public zone: NgZone,
    //public scroll: ScrollService,
    //private parallax: ParallaxService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    const textCanvas = this.doc.createElement('canvas');
    this.textCTX = textCanvas.getContext('2d');
    this.isTouchScreen = this.isTouch();

    this.wh = window.innerHeight;
    this.ww = window.innerWidth;
    this.sww = this.doc.documentElement.clientWidth;
    this.doc.documentElement.style.setProperty('--width-100',this.sww + 'px'); 

    this.zone.runOutsideAngular(() => {
      fromEvent(window,'scroll')
      .pipe(
        tap(() => {
          this.pageY = window.pageYOffset;
          this.scroll$.next({
            offsetY: this.pageY,
            dir: Math.sign(this.pageY - this.previousY)
          });
          this.previousY = this.pageY;
        }),
      )
      .subscribe(() => {
        //this.scroll.updateScroll(this.pageY);
      });

      fromEvent(window,'resize')
      .subscribe(() => {
        const ww = window.innerWidth;

        if(ww > 500 || ww != this.ww) {
          this.pageY = window.pageYOffset;
          this.sww = this.doc.documentElement.clientWidth;
          this.wh = window.innerHeight;
          this.ww = ww;
          this.doc.documentElement.style.setProperty('--width-100',this.sww + 'px'); 
          this.isTouchScreen = this.isTouch();
          //this.three.resize();
  
          this.resize$.next();
        }
      });

      // this.scroll.resizeFired$
      //   .subscribe(() => {
      //     this.pageY = window.pageYOffset;
      //   })

      requestAnimationFrame(this.animateLoop.bind(this));
    });
  }

  public init(view: HTMLElement,canvas: HTMLCanvasElement) : void {
    this.viewEl = view;
    this.appCanvas = canvas;
    //this.three = new ThreeRenderer();
    //this.three.initEngine(view,canvas);
  }

  public animateLoop(time:number) : void {
    this.raf = requestAnimationFrame(this.animateLoop.bind(this));
    this.fElapsed = time - this.fThen;

    if(this.fElapsed > this.fpsInterval) {
      this.fThen = time - (this.fElapsed % this.fpsInterval);

      // this.three.u_time.value = this.three.clock.getElapsedTime();
      // this.scroll.render();
      // this.parallax.animate(this.scroll.tY.previous);
      this.render$.next(time);
    }
  }

  public isTouch() : boolean {
    return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0));
  }

  public resetPage() : void {
    window.scrollTo(0,0);
    this.pageY = 0;
  }

  public cancelLoop() : void {
    cancelAnimationFrame(this.raf);
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @ViewChild('sidenavRef',) sidenavRef: SidenavComponent;
  public menuState: boolean = true;
  public exitState: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleSidenav() : void {
    this.sidenavRef.open();
  }

  menuOutput($event) : void {
    this.menuState = $event;
  }

  exitOutput($event) : void {
    this.exitState = $event;
  }
}

<nav class="nav">
  <div class="nav__main">
    <a routerLink="/" class="nav__logo">
      <img class="nav__logo__img" src="/assets/icons/eltons-logo.jpg">
    </a>

    <div class="nav__links">
      <a class="paragraph-one nav__links__link nav__links__link-home" routerLink="/"><span class="nav__links__link-animation-home"></span>Home</a>
      <a class="paragraph-one nav__links__link nav__links__link-pricing" routerLink="/pricing"><span class="nav__links__link-animation-pricing"></span>Pricing</a>
      <a class="paragraph-one nav__links__link nav__links__link-faqs" routerLink="/faq"><span class="nav__links__link-animation-faqs"></span>FAQ</a>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLScTH-5GEr5bNj9NnihdNvjUKhVw4hbV03ZGWbhN5tI4aeIMYg/viewform" target="_blank" class="paragraph-one nav__links__btn">Book an Appointment</a>
    </div>
  </div>

  <div class="nav__mobile">
    

    <a routerLink="/" class="nav__logo">
      <img class="nav__logo__img" src="/assets/icons/eltons-logo.jpg">
    </a>

    <app-sidenav #sidenavRef (exitOutput)="exitOutput($event)" (menuOutput)="menuOutput($event)"></app-sidenav>

    <div (click)="toggleSidenav()" class="nav__top">
      <div class="nav__menu">
        <span [ngStyle]="{'opacity' : menuState ? '1' : '0'}" class="nav__menu__line nav__menu__line--top"></span>
        <span [ngStyle]="{'opacity' : menuState ? '1' : '0'}" class="nav__menu__line nav__menu__line--btm"></span>

        <div [ngStyle]="{'opacity' : exitState ? '1' : '0'}" class="nav__exit">
          <div class="nav__exit__wrapper">
            <span class="nav__exit__line nav__exit__line--top"></span>
            <span class="nav__exit__line nav__exit__line--btm"></span>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</nav>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilModule } from './modules/util/util.module';
import { HttpClientModule } from '@angular/common/http';
import { NavComponent } from './components/navigation/nav/nav.component';
import { SidenavComponent } from './components/navigation/sidenav/sidenav.component';
import { FooterComponent } from './components/navigation/footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { PricingPageComponent } from './pages/pricing-page/pricing-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SidenavComponent,
    FooterComponent,
    HomepageComponent,
    FaqPageComponent,
    PricingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UtilModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

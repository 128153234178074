<div class="faq">
  <h1 class="heading-primary">
    Frequently Asked Questions
  </h1>

  <div class="faq__block">
    <h2 class="heading-secondary">Do you do Fades?</h2>
    <p class="paragraph-one faq__block__one">Yes, we do. Low Fades, Mid Fades, High Fades, Burst Fades, Taper Fades and Street Fades</p>
    <p class="emoji-text">🥊</p>
  </div>

  <div class="faq__block">
    <h2 class="heading-secondary">How long does the haircut take?</h2>
    <p class="paragraph-one">The duration can vary depending on the service and individual preferences. On average, a haircut takes around 30-45 minutes, while other services may take longer.</p>
  </div>

  <div class="faq__block">
    <h2 class="heading-secondary">Do I need to make an appointment or walk in?</h2>
    <p class="paragraph-one">If there is availability at the time of the walk in they are accepted, although this can not be guaranteed. By making an appointment it will guarantee you a spot at time reserved.</p>
  </div>

  <div class="faq__block">
    <h2 class="heading-secondary">What forms of payment do you accept?</h2>
    <p class="paragraph-one">We accept various forms of payment, including cash, credit/debit cards, and mobile payment options. We do offer a cash discount to save on credit/debit cards and service fees.</p>
  </div>
</div>
<div class="pricing">
  <h1 class="heading-primary">
    Pricing
  </h1>
  <div class="pricing__block">
    <h2 class="heading-secondary">Haircut</h2>
    <h2 class="heading-secondary">$25</h2>
  </div>
  <div class="pricing__block">
    <h2 class="heading-secondary">Beard</h2>
    <h2 class="heading-secondary">$15</h2>
  </div>
  <div class="pricing__block">
    <h2 class="heading-secondary">Haircut & Beard</h2>
    <h2 class="heading-secondary">$35</h2>
  </div>
  <div class="pricing__block">
    <h2 class="heading-secondary">Eyebrow shape up</h2>
    <h2 class="heading-secondary">$10</h2>
  </div>
  <div class="pricing__block">
    <h2 class="heading-secondary">Color</h2>
    <h2 class="heading-secondary">Price varies based on consultation</h2>
  </div>
  <div class="pricing__block">
    <h2 class="heading-secondary">Perm</h2>
    <h2 class="heading-secondary">Price varies based on consultation</h2>
  </div>
</div>
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { PricingPageComponent } from './pages/pricing-page/pricing-page.component';


const routes: Routes = [{
  path: "",
  component: HomepageComponent
},
{
  path: "faq",
  component: FaqPageComponent
},
{
  path: "pricing",
  component: PricingPageComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

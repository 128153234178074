import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { animate, easeIn } from 'popmotion';
import { easeInOutSine } from './../../../constants/timing-funcs.const';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @ViewChild('sidenavRef') sidenavRef: ElementRef<HTMLElement>;
  @ViewChild('linksRef') linksRef: ElementRef<HTMLElement>;
  @Output() menuOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exitOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  private toggled: boolean = false;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  open() : void {
    this.renderer.setStyle(this.sidenavRef.nativeElement, 'display', 'block');
    if(!this.toggled) {
      this.animateSidenav(0, 1);
      this.toggled = true;
      this.menuOutput.emit(false);
      this.exitOutput.emit(true);
      this.renderer.setStyle(this.linksRef.nativeElement, 'opacity', 1);
    } else if(this.toggled) {
      this.animateSidenav(1, 0);
      this.toggled = false;
      this.menuOutput.emit(true);
      this.exitOutput.emit(false);
      this.renderer.setStyle(this.linksRef.nativeElement, 'opacity', 0);
    }
    //this.animateLinks();
    
  }

  animateSidenav(from: number, to: number) : void {
    animate({
      from: from,
      to: to,
      duration: 300,
      ease: easeInOutSine,
      onUpdate: (d) => {
        this.renderer.setStyle(this.sidenavRef.nativeElement, 'transform', `scaleY(${d})`);
      }
    })
  }

  animateLinks() : void {
    animate({
      from: 0,
      to: 1,
      duration: 300, 
      elapsed: -100,
      ease: easeInOutSine,
      onUpdate: (d) => {
        this.renderer.setStyle(this.linksRef.nativeElement, 'opacity', d)
      },
    })
  }
}

import { Component, OnInit } from '@angular/core';

declare global {
  interface Window {
    instgrm: any;
  }
}

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  private activeIdx: number = 1;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    window.instgrm.Embeds.process();
  }

  public switchReview(dir: number) {
    if(dir > 0) {

      if(this.activeIdx === 1) {
        this.activeIdx++;
        // hide review #1
        document.getElementById('review_one').classList.add('hide-me');
        document.getElementById('review_one').classList.remove('show-me');
        // show review #2
        document.getElementById('review_two').classList.add('show-me');
        document.getElementById('review_two').classList.remove('hide-me');
      } else if(this.activeIdx === 2) {
        this.activeIdx++;
        // hide review #2
        document.getElementById('review_two').classList.add('hide-me');
        document.getElementById('review_two').classList.remove('show-me');
        // show review #3
        document.getElementById('review_three').classList.add('show-me');
        document.getElementById('review_three').classList.remove('hide-me');
      } else if(this.activeIdx === 3) {
        this.activeIdx = 1;
        // hide review #3
        document.getElementById('review_three').classList.add('hide-me');
        document.getElementById('review_three').classList.remove('show-me');
        // show review #1
        document.getElementById('review_one').classList.add('show-me');
        document.getElementById('review_one').classList.remove('hide-me');
      }
    } else {
      
      if(this.activeIdx === 1) {
        this.activeIdx = 3;
        // hide review #1
        document.getElementById('review_one').classList.add('hide-me');
        document.getElementById('review_one').classList.remove('show-me');
        // show review #3
        document.getElementById('review_three').classList.add('show-me');
        document.getElementById('review_three').classList.remove('hide-me');
      } else if(this.activeIdx === 2) {
        this.activeIdx--;
        // hide review #2
        document.getElementById('review_two').classList.add('hide-me');
        document.getElementById('review_two').classList.remove('show-me');
        // show review #1
        document.getElementById('review_one').classList.add('show-me');
        document.getElementById('review_one').classList.remove('hide-me');
      } else if(this.activeIdx === 3) {
        this.activeIdx--;
        // hide review #3
        document.getElementById('review_three').classList.add('hide-me');
        document.getElementById('review_three').classList.remove('show-me');
        // show review #2
        document.getElementById('review_two').classList.add('show-me');
        document.getElementById('review_two').classList.remove('hide-me');
      }
    }
  }

}
